import PropTypes from 'prop-types';
import React from 'react';
import { GetInTouch } from '../components/Common';
import {
  ImportCareers,
  CareersExploreChallenges,
  CareersHero,
  CareersPerksBenefits,
  CareersQuotesSlider,
} from '../components/Careers';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Index = ({ location }) => (
  <>
    <SEO title="Careers" description="Open Positions" location={location} />
    <Layout location={location}>
      <CareersHero />
      <CareersPerksBenefits />
      <CareersExploreChallenges />
      <ImportCareers />
      <CareersQuotesSlider />
      <GetInTouch />
    </Layout>
  </>
);

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Index;
