import Slider from 'react-slick';
import React from 'react';

import './QuotesSlider.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import openingQuote from '../../../images/opening-quote.svg';
import closingQuote from '../../../images/closing-quote.svg';

const QuoteSlider = () => {
  const colleenQuote = 'If you help people become the best version of themselves you get the best version of your company. We are focused on building dreams for employees and our clients.';
  const theoQuote = "We recognize that we're entrusted with peoples' livelihoods, be they customers or employees, and take that responsibility seriously - focusing on doing the right thing over expediency or short term gains.";
  const benQuote = 'When we set out to start Build, our vision was to leverage our vast knowledge gained over decades in the corporate world and help small startups gain an edge in a very cutthroat industry. Our team has a proven track record guiding our clients towards success.';
  const kierstiQuote = 'We assist founders and owners with taking their dreams and visions to reality. From a doodle on a paper napkin to supporting end users of the products built, and anywhere in between. Our Dream Builders, clients and employees, are some of the most passionate, knowledgeable, energized, creative and fun loving group that I have ever worked with.';
  const diegoQuote = 'Build is a place where you can have real impact on projects, all ideas are heard, and it really is a team working and looking for the success of our clients.';
  const marianaQuote = 'Build represents union and family, a place without prejudice, where everyone is welcome and will always have a place to be special in their own way. Build is a place where your voice matters, they are always willing to listen to your ideas no matter how crazy they are and they will always look for ways to make your talent shine and be the best version of yourself.';
  const data = useStaticQuery(
    graphql`
      query {
        colleenImg: file(relativePath: { eq: "colleen-quote.png" }) {
          ...squareImage
        }
        theoImg: file(relativePath: { eq: "theo-quote.png" }) {
          ...squareImage
        }
        benImg: file(relativePath: { eq: "ben-quote.png" }) {
          ...squareImage
        }
        kierstiImg: file(relativePath: { eq: "kiersti-quote.png" }) {
          ...squareImage
        }
        diegoImg: file(relativePath: { eq: "diego-quote.png" }) {
          ...squareImage
        }
        marianaImg: file(relativePath: { eq: "mariana-quote.png" }) {
          ...squareImage
        }
      }
    `,
  );

  const people = [
    {
      name: 'Colleen Howell',
      position: 'CEO',
      quote: colleenQuote,
      photo: 'colleenImg',
    },
    {
      name: 'Theo Peterson',
      position: 'CISO',
      quote: theoQuote,
      photo: 'theoImg',
    },
    {
      name: 'Ben Fisher',
      position: 'CTO',
      quote: benQuote,
      photo: 'benImg',
    },
    {
      name: 'Kiersti Felska',
      position: 'CXO',
      quote: kierstiQuote,
      photo: 'kierstiImg',
    },
    {
      name: 'Diego Gutierrez',
      position: 'Software Developer',
      quote: diegoQuote,
      photo: 'diegoImg',
    },
    {
      name: 'Mariana Abellan',
      position: 'UI/UX Designer',
      quote: marianaQuote,
      photo: 'marianaImg',
    },
  ];

  return (
    <Container
      fluid
      className="management-slider-container mt-5 pt-5 pb-3"
    >
      <Slider
        dots
        infinite
        speed={500}
        arrows={false}
        slidesToShow={1}
      >
        {people.map((person) => (
          <div key={person.name}>
            <Row className="quote-wrapper justify-content-center">
              <Col xs={12} sm={12} md={12} className="align-self-center">
                <Row>
                  <Col xs={2} sm={2} md={2} className="d-flex justify-content-end align-self-start">
                    <img src={openingQuote} alt='"' className="opening-quote" />
                  </Col>
                  <Col xs={8} sm={8} md={8}>
                    <h3 className="quote">{person.quote}</h3>
                  </Col>
                  <Col xs={2} sm={2} md={2} className="align-self-end">
                    <img src={closingQuote} alt='"' className="closing-quote" />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center person-container">
                  <GatsbyImage
                    image={data[person.photo].childImageSharp.gatsbyImageData}
                    className="person-image"
                    alt=""
                  />
                  <div className="d-flex person-information">
                    <h4 className="person-name">{person.name}</h4>
                    <p className="text-primary person-position">
                      {`${person.position}, Build`}
                    </p>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default QuoteSlider;
