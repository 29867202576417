import React from 'react';
import Container from 'react-bootstrap/Container';
import './CareersExploreChallenges.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import newTechnologiesIcon from '../../../images/new-technologies-benefit.svg';
import workingWithStartupsIcon from '../../../images/working-startups-benefit.svg';
import professionalGrowthIcon from '../../../images/business-development.svg';

import blueCurve from '../../../images/blue-curve.svg';

const CareersExploreChallenges = () => {
  const data = useStaticQuery(
    graphql`{
  teamCircle: file(relativePath: {eq: "careers-circle-team.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container
      className="explore-team-container position-relative d-flex flex-column
                    justify-content-center align-items-start px-0 pb-3 pb-md-5"
      fluid
    >
      <div className="team-circle d-flex position-absolute">
        <GatsbyImage
          image={data.teamCircle.childImageSharp.gatsbyImageData}
          title="Team Circle"
          alt="Team Circle image"
        />
      </div>
      <div className="content-container d-flex flex-column align-items-center my-0 my-sm-1 py-1 py-sm-5">
        <div
          className="title-benefits-container d-flex flex-column position-relative
                    mt-1 mt-sm-3 mt-md-5 py-5 px-2 px-md-5 justify-content-center
                    justify-content-sm-end text-end"
        >
          <p className="title fw-bold my-1 my-sm-2 my-md-3 mx-4 mx-md-5 pe-3 pe-md-5">
            Explore New Challenges!
          </p>
          <p className="my-1 my-sm-2 my-md-3 mx-4 mx-md-5 text-end ps-0 ps-sm-5 pe-3 pe-md-5">
            Build works with exciting startups and early stage companies around
            the globe. This affords our team the opportunity to work with
            cutting edge technology and varied tech stack methodologies and
            tools.
          </p>
          <div
            className="icons-benefits-container d-flex flex-column flex-sm-row
                            position-relative justify-content-center justify-content-md-end
                            mt-3 mx-0 mx-sm-2 mx-md-5 pe-sm-5"
          >
            <div className="icon d-flex flex-column align-items-center text-center mx-2 mx-md-5 mt-0 mt-sm-3 mb-3">
              <img
                src={professionalGrowthIcon}
                alt="Professional Development"
                title="Professional Development Icon"
              />
              <p className="mt-3">Professional Development</p>
            </div>
            <div className="icon d-flex flex-column align-items-center text-center mx-2 mx-md-5 mt-0 mt-sm-3 mb-3">
              <img
                src={workingWithStartupsIcon}
                alt="Working with Startups"
                title="Working with Startups Icon"
              />
              <p className="mt-3">Working with Startups</p>
            </div>
            <div className="icon d-flex flex-column align-items-center text-center me-0 ms-2 ms-md-5 mt-0 mt-sm-3 mb-3">
              <img
                src={newTechnologiesIcon}
                alt="New Technologies Icon"
                title="New Technologies"
              />
              <p className="mt-3">New Technologies</p>
            </div>
          </div>
          <div className="d-flex position-absolute blue-curve align-items-start">
            <img
              src={blueCurve}
              alt="Build Curve blue"
              title="Build Curve blue"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CareersExploreChallenges;
