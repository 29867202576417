import React from 'react';
import Container from 'react-bootstrap/Container';
import './CareersPerksBenefits.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import unlimitedVacationIcon from '../../../images/vacation-benefit.svg';
import flexibleScheduleIcon from '../../../images/schedule-benefit.svg';
import remoteWorkIcon from '../../../images/remote-work-benefit.svg';
import medicalInsuranceIcon from '../../../images/healthcare-icon.svg';
import lightBlueCurve from '../../../images/light-blue-curve.svg';

const CareersPerksBenefits = () => {
  const data = useStaticQuery(
    graphql`{
  montanaCircle: file(relativePath: {eq: "careers-circle-mt.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container
      className="join-team-container position-relative d-flex flex-column
                    justify-content-center align-items-end px-0 pt-3 pt-md-5 mt-1 mt-md-5"
      fluid
    >
      <div className="montana-circle d-flex position-absolute">
        <GatsbyImage
          image={data.montanaCircle.childImageSharp.gatsbyImageData}
          title="Montana Circle"
          alt="Montana Circle image"
        />
      </div>
      <div className="title-best-team d-flex mt-1 mt-sm-3 mt-md-5 pe-sm-5 justify-content-center">
        <p className="fw-bold my-1 my-sm-2 my-md-3">Join the Best Team!</p>
      </div>
      <div className="content-section-container d-flex flex-column align-items-center
                        my-1 my-sm-3 my-md-5 py-0 py-sm-3"
      >
        <div className="title-benefits d-flex flex-column position-relative
                    mt-1 mt-sm-3 mt-md-5 px-3 px-sm-5 py-3 py-md-5 justify-content-center"
        >
          <p className="title fw-bold my-0 my-sm-2 my-md-3 mx-0 mx-md-5">Builder Perks!</p>
          <p className="my-1 my-sm-2 my-md-3 mx-0 mx-sm-3 mx-md-5 pe-0 pe-sm-3 pe-md-5">
            To assist with becoming the best version of yourself,
            we offer the following personal perks to our builders:
          </p>
          <div className="icons-container d-flex flex-column flex-sm-row justify-content-start mt-3">
            <div className="icon d-flex flex-column align-items-center text-center my-3 mx-3 mx-md-5">
              <img
                src={unlimitedVacationIcon}
                alt="Unlimited Vacation Icon"
                title="Unlimited Vacation"
              />
              <p className="mt-3">Unlimited Vacation</p>
            </div>
            <div className="icon d-flex flex-column align-items-center text-center my-3 mx-3 mx-md-5">
              <img
                src={flexibleScheduleIcon}
                alt="Unlimited Vacation Icon"
                title="Unlimited Vacation"
              />
              <p className="mt-3">Flexible Schedule</p>
            </div>
            <div className="icon d-flex flex-column align-items-center text-center my-3 mx-3 mx-md-5">
              <img
                src={medicalInsuranceIcon}
                alt="Private Medical Insurance"
                title="Private Medical Insurance"
              />
              <p className="mt-3">
                Private Medical
                <br />
                Insurance
              </p>
            </div>
            <div className="icon d-flex position-relative flex-column align-items-center text-center my-3 mx-3 mx-md-5">
              <img
                src={remoteWorkIcon}
                alt="Unlimited Vacation Icon"
                title="Unlimited Vacation"
              />
              <p className="mt-3">
                Remote Work
                <br />
                Opportunities
              </p>
            </div>
          </div>
          <div className="d-flex position-absolute light-blue-curve align-self-end">
            <img
              src={lightBlueCurve}
              alt="Build Curve light blue"
              title="Build Curve light blue"
            />
          </div>
        </div>
      </div>

    </Container>
  );
};

export default CareersPerksBenefits;
