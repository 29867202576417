import React from 'react';
import Container from 'react-bootstrap/Container';
import QuoteSlider from './QuotesSlider';
import './CareersQuotesSlider.scss';

const CareersQuotesSlider = () => (
  <Container className="careers-quotes-container position-relative d-flex flex-column justify-content-center align-items-center px-0 py-3 py-md-5" fluid>
    <div className="title-quotes d-flex mt-1 mt-sm-3 mt-md-5">
      <p className="fw-bold my-1 my-sm-2 my-md-3">What Our Team Says</p>
    </div>
    <QuoteSlider />
  </Container>
);

export default CareersQuotesSlider;
