import React, { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Loader } from '../../Common';
import './ImportCareers.scss';

const ImportCareers = () => {
  const [loading, setLoading] = useState(true);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const positionsWrapper = useRef(null);
  const [positionsWrapperHeight, setPositionsWrapperHeight] = useState('400px');

  const GATSBY_CAREERS_DOMAIN = process.env.GATSBY_CAREERS_DOMAIN;

  useEffect(() => {
    if (!frameLoaded) return;
    const resumatorResizeParentIframe = () => {
      const ph = resumatorGetParam('height');
      const ju = resumatorGetParam('nojump');
      if (window.parent?.parent) {
        window.onload = () => {
          if(window.parent.parent.resizeResumatorIframe && typeof window.parent.parent.resizeResumatorIframe === 'function') {
            window.parent.parent.resizeResumatorIframe(ph, ju);
          }
        };
      }
    };

    // Helper function, parse param from request string
    const resumatorGetParam = (p) => {
      const paragraph = p.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const rs = `[\\?&]${paragraph}=([^&#]*)`;
      const r = new RegExp(rs);
      const results = r.exec(window.location.href);
      if (results == null) {
        return "";
      }
      return results[1];
    };

    // Call the function when the component mounts
    resumatorResizeParentIframe();
  }, [frameLoaded]);

  useEffect(() => {
    if (!GATSBY_CAREERS_DOMAIN) return;
    const iframeHTML = `<iframe name="resumator-job-frame" id="resumator-job-frame" class="resumator-advanced-widget" src="${GATSBY_CAREERS_DOMAIN}" width="100%" height="100%" scrolling="yes" frameborder="0" allowTransparency="true"></iframe>`;
    positionsWrapper.current.innerHTML = iframeHTML;
    setLoading(false);
    setFrameLoaded(true);
  }, [GATSBY_CAREERS_DOMAIN]);

  useEffect(() => {
    if (!frameLoaded) return;

    const resumatorResizeParentIframe = () => {
      const ph = resumatorGetParam('height');
      const ju = resumatorGetParam('nojump');
      if (window.parent?.parent) {
        window.onload = () => {
          if (window.parent.parent.resizeResumatorIframe && typeof window.parent.parent.resizeResumatorIframe === 'function') {
            window.parent.parent.resizeResumatorIframe(ph, ju);
          }
        };
      }
    };

    const handleResize = () => {
      setPositionsWrapperHeight(`${positionsWrapper.current.clientHeight + 4 * 16}px`); // 4 rem in pixels
    };

    // Call the function when the component mounts
    resumatorResizeParentIframe();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [frameLoaded]);

  return (
    <>
      <h2 className="section-title mt-5">Our Positions</h2>
      <Container className="d-flex flex-column justify-content-center align-items-center p-0" fluid>
        <Loader visible={loading} />
        <div style={{ width: '80%', overflowYL: 'auto', margin: '0 auto' }}>
          <p className="title fw-bold my-1 my-sm-2 my-md-3 mx-4 mx-md-5 pe-3 pe-md-5">
            <a href={GATSBY_CAREERS_DOMAIN} target="_blank" rel="noreferrer">View all jobs</a>
          </p>
          <div
            ref={positionsWrapper}
            style={{ width: '100%', fontSize: '2rem', height: positionsWrapperHeight, overflow: 'auto' }}
          />
        </div>
      </Container>
    </>
  );
};

export default ImportCareers;
