import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import './CareersHero.scss';

const CareersHero = () => {
  const data = useStaticQuery(
    graphql`{
  file(relativePath: {eq: "careers-hero.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container className="careers-hero-container position-relative d-flex flex-column justify-content-center align-items-center p-0" fluid>
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        title="Careers Hero"
        alt="Careers Hero image"
      />

      <div className="hero-text-container position-absolute d-flex flex-column align-items-start justify-content-center">
        <div className="hero-text d-flex flex-column text-start">
          <p className="title fw-bold p-0 m-0">Work at Build</p>
          <p className="subtitle text-start p-0 m-0">
            Launch a new chapter in your career!
          </p>
        </div>
      </div>
    </Container>
  );
};

export default CareersHero;
